import React from "react"
import "../../components/animations/scroll.scss"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../../components/ImageMeta"

function StaticHeading(props) {
  const { post } = props
  const {
    staticHero: { heading, subHeading, logo }
  } = post
  return (
    <div
      style={{
        textAlign: "left"
      }}
      className="static-heading">
      {/* <ImageMeta
        className="static-heading__logo"
        publicId={logo}
        cloudname="nuvolum"
        // format="png"
      /> */}
      <h1 dangerouslySetInnerHTML={{ __html: heading }} />
      <div
        className="above-text-container"
        style={{ opacity: "1", transition: "unset" }}>
        <p
          className="static-heading__blurb"
          dangerouslySetInnerHTML={{ __html: subHeading }}
        />
      </div>

      {props.post.sizzleYoutube && props.post.sizzleYoutube !== "?" && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          sizzleButton={true}
          buttonClass="sizzle-button-under contained"
          compId={"sizzleTheaterButton"}
          language={props.language}
        />
      )}
    </div>
  )
}

export default StaticHeading
