import React from "react"
import { VideoWrapped } from "../../components/reactPlayerTest"
import "../../components/animations/scroll.scss"
import CursorOverContainer from "../../components/cursor/CursorOverContainer"
import TheaterVideo from "@components/Theater/TheaterVideo"

import { isMobileOnly } from 'react-device-detect';

function Sizzle(props) {

  let dyStart = -3.5;
  // if (props.post.svgHeading.length === 4) {
  //   dyStart = -3.5;
  // }
  return (
    <div
      className="home-sizzle-wrapper"
    >
      <VideoWrapped
        playing={props.playing}
        id="test-player-1"
      />
      {props.children}
    </div>
  )
}

export default Sizzle
