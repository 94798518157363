import React from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons/faChevronRight"
import ImageMeta from "../ImageMeta"

const BeforeAndAfter = ({ cards }) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <CustomArrow isRight />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false
        }
      }
    ]
  }

  return (
    <div className="before-after">
      {/* <FontAwesomeIcon
        icon={faChevronLeft}
        className="before-after__arrow left"
      /> */}
      <Slider {...settings}>
        {cards.map((card, i) => (
          <Card
            key={card.name}
            name={card.name}
            before={card.beforeImage}
            after={card.afterImage}
          />
        ))}
      </Slider>
      {/* <FontAwesomeIcon
        icon={faChevronRight}
        className="before-after__arrow right"
      /> */}
    </div>
  )
}

const CustomArrow = ({ onClick, isRight }) => {
  return (
    <FontAwesomeIcon
      className={` before-after__arrow ${isRight ? "right" : "left"}`}
      onClick={onClick}
      icon={isRight ? faChevronRight : faChevronLeft}
    />
  )
}

const Card = ({ name, before, after }) => {
  return (
    <div className="before-after__card">
      <div className="before-after__images">
        <div>
          <ImageMeta cloudname="nuvolum" publicId={before} />
          <p>BEFORE</p>
        </div>
        <div>
          <ImageMeta cloudname="nuvolum" publicId={after} />
          <p>AFTER</p>
        </div>
      </div>
      <div>
        <p className="before-after__name">{name}</p>
      </div>
    </div>
  )
}

export default BeforeAndAfter
