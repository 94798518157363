import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import BeforeAndAfter from "../../components/carousel/BeforeAndAfter"

import ImageMeta from "../../components/ImageMeta"

function ImageText(props) {
  const { beforeAfter } = props
  // if no complimentary image
  if (!props.post.homeSectionOne.imageId) {
    return (
      <section
        className={
          `home-section text-picture home-section-1 top-down` +
          `${props.colorBack ? "color-back" : ""}`
        }>
        <div className="columns">
          <div className="column is-3" />
          <div id="first-column" className="column is-18">
            {props.post.homeSectionOne.heading && (
              <MarkdownViewer
                className="has-text-centered"
                noWidow
                markdown={props.post.homeSectionOne.heading}
              />
            )}
            {beforeAfter && (
              <div style={{ marginBottom: "40px" }}>
                <BeforeAndAfter cards={beforeAfter} />
              </div>
            )}
            <MarkdownViewer noWidow markdown={props.post.homeSectionOne.text} />
            {props.post.homeSectionOne.buttons && (
              <ButtonGroupMap
                isCentered
                noReverse={props.noReverse}
                buttons={props.post.homeSectionOne.buttons}
              />
            )}
          </div>
          <div className="column is-3" />
        </div>
      </section>
    )
  }
  if (props.topDown) {
    return (
      <section className="home-section text-picture home-section-1 top-down">
        <div className="columns">
          <div className="column is-2" />
          <div data-aos="fade-up" data-aos-duration="800" id="first-column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.post.homeSectionOne.imageId}
              src={`https://res.cloudinary.com/nuvolum/image/upload/q_10/v1/${props.post.homeSectionOne.imageId}`}
              width="auto"
              responsive
              className="full-width"
            />
          </div>
          <div className="column is-2" />
        </div>
        <div className="columns">
          <div className="column is-3" />
          <div
            className="column"
            data-aos="fade-up"
            data-aos-duration="800"
            id="first-column">
            <MarkdownViewer
              noWidow
              className="has-text-centered"
              markdown={props.post.homeSectionOne.heading}
            />
            <MarkdownViewer noWidow markdown={props.post.homeSectionOne.text} />

            <ButtonGroup isCentered noReverse={props.noReverse}>
              <Button
                buttonText={props.post.homeSectionOne.buttonOne.buttonText}
                minimal
                href={props.post.homeSectionOne.buttonOne.href}
              />
              <Button
                buttonText={props.post.homeSectionOne.buttonTwo.buttonText}
                href={props.post.homeSectionOne.buttonTwo.href}
              />
            </ButtonGroup>
          </div>
          <div className="column is-3" />
        </div>
      </section>
    )
  }
  if (props.textImage || props.imageText) {
    return (
      <LeftRight
        leftColumn={<TextColumn {...props} />}
        rightColumn={<PictureColumn {...props} />}
      />
    )
  }
}

function LeftRight(props) {
  return (
    <section
      className={
        "home-section text-picture home-section-1" +
        `${props.colorBack ? "color-back" : ""}`
      }>
      <div className="columns is-vcentered reverse-columns">
        <div className="column is-2" />
        {props.leftColumn}
        <div className="column is-2 is-hidden-touch-fix" />
        {props.rightColumn}
        <div className="column is-2" />
      </div>
    </section>
  )
}

function TextColumn(props) {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      id="first-column"
      className="column text-column mobile-pad">
      <div className="columns">
        <div className="column">
          <MarkdownViewer
            noWidow
            markdown={props.post.homeSectionOne.heading}
          />
          <MarkdownViewer noWidow markdown={props.post.homeSectionOne.text} />
          {props.post.homeSectionOne.buttons && (
            <ButtonGroupMap
              noReverse={props.noReverse}
              buttons={props.post.homeSectionOne.buttons}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function PictureColumn(props) {
  return (
    <div className="column is-10 picture-column is-hidden-touch-fix">
      <div className="columns">
        <div
          className="column"
          data-aos-duration="1200"
          data-aos="fade-left"
          data-aos-anchor-placement="center-bottom">
          <ImageMeta
            cloudName="nuvolum"
            publicId={props.post.homeSectionOne.imageId}
            data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.post.homeSectionOne.imageId}`}
            width="auto"
            responsive
          />
        </div>
      </div>
    </div>
  )
}

export default ImageText
